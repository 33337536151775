.wallet-shadow-gray {
  box-shadow: 0 8.69px 10.27px -4.74px #7682846E;
}

.wallet-shadow-low {
  box-shadow: 0 13.29px 15.71px -7.25px #76C9356E;
}

.wallet-shadow-medium {
  box-shadow: -1px 10.8px 12.76px -5.89px #C9A0356E;
}

.wallet-shadow-high {
  box-shadow: -1px 13.29px 15.71px -7.25px #C935356E;
}

.wallet-shadow-severe {
  box-shadow: -2px 5.69px 10.27px -5.74px #FF0A00;
}
