@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;

@use '../utils';
@use './tokens';

@mixin m2-color($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);

  @include utils.create-token-values(tokens.get-color-tokens($config, primary));

  .mat-timepicker-content {
    &.mat-accent {
      @include utils.create-token-values(tokens.get-content-color-tokens($config, accent));
    }

    &.mat-warn {
      @include utils.create-token-values(tokens.get-content-color-tokens($config, warn));
    }
  }

  .mat-timepicker-toggle-active {
    &.mat-accent {
      $accent-tokens: tokens.get-toggle-color-tokens($config, accent);
      @include utils.create-token-values($accent-tokens);
    }

    &.mat-warn {
      $warn-tokens: tokens.get-toggle-color-tokens($config, warn);
      @include utils.create-token-values($warn-tokens);
    }
  }
}
