.project-table {
  .mat-mdc-table {
    height: fit-content;
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    color: #022D35;
    font-size: 14px;

    .mdc-data-table__row {
      height: 52px;
      font-family: "Inter", sans-serif;
    }

    .mat-mdc-header-row {
      height: 40px;
      background-color: #F9FAFB;
      border-bottom: 1px solid #E5E7EB;
      font-family: "Inter", sans-serif;

      th {
        vertical-align: middle;
        text-transform: uppercase;
        border-bottom: none;
        color: #6B7280;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.6px;
        font-family: inherit;
      }
    }

    .mat-mdc-row {
      &:last-child td {
        border-bottom-color: transparent;
      }
    }

    .mat-mdc-header-cell {
      text-align: left;
      padding: 0 6px 0 6px;
    }

    .mat-mdc-cell {
      min-width: 40px;
      padding: 0 6px 0 6px;
      vertical-align: middle;
    }

    .mat-sort-header-button {
      text-align: left;
    }

    .mat-column {
      .mat-sort-header-container {
        text-align: left;
      }
    }

    tbody {
      td {
        font-size: 14px;
        font-family: "Inter", sans-serif;
      }

      .mat-mdc-row {
        position: relative;

        &::after {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 5px;
          z-index: 1;
        }
      }
    }
  }
}

.light {
  .project-table {
    .mat-mdc-table {
      tbody {
        td {
          color: #6B7280;
        }
      }
    }
  }
}

.dark {
  .project-table {
    .mat-mdc-table {
      tbody {
        td {
          color: white;
        }
      }
    }
  }
}
