@use '@angular/material' as mat;

@use '../utils';
@use './tokens';

@mixin m2-base() {
  @include utils.create-token-values(tokens.get-unthemable-tokens());

  .mat-timepicker-content {
    @include mat.elevation(4);
  }
}
