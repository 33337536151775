::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
}

.light {
  ::-webkit-scrollbar-thumb {
    background: rgba(black, 0.2);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(black, 0.4);
  }
}

.dark {
  ::-webkit-scrollbar-thumb {
    background: rgba(white, 0.4);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(white, 0.6);
  }
}
