@import "assets/scss/mat-theme",
"assets/scss/scroll",
"assets/scss/table",
"assets/scss/tooltip",
"assets/scss/form-field",
"assets/scss/wallet-style.scss",
"assets/scss/sidenav";

@tailwind base;
@tailwind components;
@tailwind utilities;
